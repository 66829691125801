import React from 'react'
import SimplePage from '../components/SimplePage'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

const Privacy = () => (
  <Layout>
    <SEO title="Cookie Policy" />
    <SimplePage>
      <h2>Cookie Policy</h2>
      <p>
        <strong>1. Premessa</strong>
      </p>
      <p>
        La presente cookie policy è riferita esclusivamente al sito web di OCF{' '}
        <strong>informarsiconviene.it</strong> e deve essere intesa come parte
        integrante della Privacy Policy del medesimo.
      </p>
      <p>
        <strong>2. Cosa sono e a cosa servono i cookie</strong>
      </p>
      <p>
        Un “cookie” è un file di testo di piccole dimensioni che può essere
        conservato in uno spazio dedicato sul disco rigido del dispositivo
        utilizzato dall’utente (ad es. computer, tablet, smartphone, ecc.) nel
        momento in cui visita un sito internet attraverso il proprio browser e
        può essere soggetto al preventivo consenso dell’utente prima di essere
        installato. Attraverso i cookie un sito ricorda le azioni e le
        preferenze dell'utente (come, ad esempio, i dati di login, la lingua
        prescelta, le dimensioni dei caratteri, altre impostazioni di
        visualizzazione, ecc.) in modo che non debbano essere indicate
        nuovamente quando l'utente torni a visitare il sito o navighi da una
        pagina all'altra di esso. I cookie, quindi, sono usati per eseguire
        autenticazioni informatiche, monitoraggio di sessioni e memorizzazione
        di informazioni riguardanti le attività degli utenti che accedono ad un
        sito e possono contenere anche un codice identificativo unico che
        consente di tenere traccia della navigazione dell'utente all'interno del
        sito stesso per finalità statistiche o pubblicitarie. Nel corso della
        navigazione su un sito, l'utente può ricevere sul suo computer o
        dispositivo mobile anche cookie di siti o di web server diversi da
        quello che sta visitando (c.d. cookie di "terze parti"). Alcune
        operazioni non potrebbero essere compiute senza l'uso dei cookie, che in
        certi casi sono quindi tecnicamente necessari per lo stesso
        funzionamento del sito.
      </p>
      <p>
        Esistono vari tipi di cookie, a seconda delle loro caratteristiche e
        funzioni, e questi possono rimanere nel computer o dispositivo mobile
        dell'utente per periodi di tempo diversi: c.d. cookie di sessione, che
        viene automaticamente cancellato alla chiusura del browser; c.d. cookie
        persistenti, che permangono sull'apparecchiatura dell'utente fino ad una
        scadenza prestabilita.
      </p>
      <p>
        In base alla normativa applicabile, per l'utilizzo dei cookie non sempre
        è richiesto un espresso consenso dell'utente. In particolare, non
        richiedono il consenso i cd. "cookie tecnici", cioè quelli utilizzati al
        solo fine di effettuare la trasmissione di una comunicazione su una rete
        di comunicazione elettronica o nella misura strettamente necessaria per
        erogare un servizio esplicitamente richiesto dall'utente. Si tratta, in
        altre parole, di cookie indispensabili per il funzionamento del sito o,
        comunque, necessari per eseguire attività richieste dall'utente. Tra i
        cookie tecnici, che non richiedono un consenso espresso per il loro
        utilizzo, il Garante per la protezione dei dati personali italiano (
        <i>
          cfr. provvedimento Individuazione delle modalità semplificate per
          l'informativa e l'acquisizione del consenso per l'uso dei cookie
          dell'8 maggio 2014 - di seguito solo "Provvedimento" - e, da ultimo,
          le Linee guida cookie e altri strumenti di tracciamento del 10 giugno
          2021 - di seguito solo “Linee Guida”
        </i>
        ) ricomprende anche:
      </p>
      <ul>
        <li>
          i cookie statistici o analitici di prima parte e di terze parti, se
          sono adottati strumenti che riducono il potere identificativo dei
          cookie e la terza parte non incrocia le informazioni raccolte con
          altre di cui già dispone, utilizzati per raccogliere informazioni, in
          forma aggregata, sul numero degli utenti e su come questi visitano il
          sito stesso;
        </li>
        <li>
          i cookie di navigazione o di sessione, la cui disattivazione
          compromette l'utilizzo dei servizi disponibili nel sito;
        </li>
        <li>
          i cookie di funzionalità, che permettono all'utente la navigazione in
          funzione di una serie di criteri selezionati (ad esempio, la lingua, i
          prodotti selezionati per l'acquisto) al fine di migliorare il servizio
          reso allo stesso.
        </li>
      </ul>
      <p>
        Viceversa, per "cookie di profilazione", cioè quelli volti a
        identificare le preferenze dell'utente al fine di migliorare e
        personalizzare la sua esperienza di navigazione e/o mostrargli
        comunicazioni marketing in linea con le preferenze espresse durante la
        navigazione, nonché per i cookie statistici o analitici di terza parte,
        se non sono adottati strumenti che riducono il potere identificativo dei
        cookie e la terza parte incrocia le informazioni raccolte con altre di
        cui già dispone, <u>è richiesto un preventivo consenso dell'utente</u>.
      </p>

      <p>
        <strong>3. Cookie utilizzati dal Sito</strong>
      </p>
      <p>
        Si informa che il Sito utilizza i seguenti cookie, i quali possono
        essere deselezionati, anche singolarmente (come specificato, di seguito,
        nella sezione “Come de-selezionare i cookie”):
      </p>

      <ul>
        <li>
          <strong>cookie tecnici di navigazione</strong>: tali cookie permettono
          al Sito di funzionare correttamente e consentono di visualizzarne i
          contenuti; una loro eventuale disattivazione comporterebbe
          malfunzionamenti del Sito. Questi cookie non vengono memorizzati in
          modo persistente sul computer dell’utente e svaniscono con la chiusura
          del browser;
        </li>
        <li>
          <strong>cookie di terze parti</strong>, cioè cookie di siti o di web
          server diversi da quelli di OCF, utilizzati per finalità sia di OCF
          sia di dette parti terze, tra cui anche cookie analitici, che
          consentono di capire come gli utenti utilizzano il Sito e di tenere
          traccia del traffico da e verso il Sito, e di{' '}
          <strong>profilazione</strong>. Un esempio è rappresentato dalla
          presenza sul Sito del “social plugin” e dei contenuti video del canale
          social Youtube utilizzato da OCF. Si tratta di parti della pagina
          visitata, generate direttamente dai suddetti siti ed integrate nella
          pagina del Sito ospitante. L'utilizzo del social plugin e dei
          contenuti video di Youtube è finalizzato alla condivisione dei
          contenuti dei social network di OCF e/o per la condivisione di
          contenuti del sito web visitato sul proprio profilo social e la loro
          presenza comporta la trasmissione di cookie di profilazione da e verso
          tutti i siti gestiti da terze parti. La disattivazione dei cookie di
          profilazione di terza parte non pregiudica l’utilizzo del Sito,
          tuttavia potrebbe compromettere la visualizzazione dei contenuti dei
          siti esterni presenti nelle varie sezioni del Sito (quali, ad esempio,
          il social plugin e i contenuti video di Youtube).
        </li>
      </ul>
      {/* <p>
        Durante la navigazione del Sito si ricevono anche cookie di Google
        Analytics con mascheramento degli indirizzi IP. Google tratta i dati
        personali in qualità di Responsabile del trattamento per conto di OCF;
        infatti, OCF e Google hanno firmato accordi per il trattamento dei dati
        ex art. 28 del Regolamento (UE) 2016/679.
      </p> */}

      <p>
        Si precisa che tali soggetti terzi, di seguito elencati con i relativi
        collegamenti alle Privacy Policy, sono tipicamente anche autonomi
        titolari del trattamento dei dati raccolti attraverso i cookie da essi
        serviti; in tal caso l’utente presta o nega il consenso direttamente al
        proprietario del cookie in questione al quale il Sito fa mero rinvio. La
        gestione delle informazioni raccolte da “terze parti” è disciplinata
        dalle loro informative privacy cui si prega di fare riferimento e di
        cui, per maggiore comodità, si riportano di seguito i link:
      </p>
      <ul>
        <li>
          <strong>Google: </strong>
          <a href="http://www.google.com/policies/technologies/cookies/">
            http://www.google.com/policies/technologies/cookies/
          </a>
        </li>
        {/* <li>
          <strong>Google Analytics: </strong>
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout
          </a>
        </li> */}
        <li>
          <strong>Youtube: </strong>
          <a href="https://policies.google.com/technologies/partner-site">
            https://policies.google.com/technologies/partner-site
          </a>
        </li>
      </ul>
      <p>
        Nel dettaglio, tutti i cookie presenti nel Sito sono indicati nella
        tabella seguente.
      </p>
      <br />
      <div className="table-wrapper">
        <table className="table-content">
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Tipo di cookie e proprietario</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Finalità</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Nome tecnico</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Tempo di persistenza</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Categoria (prima o terza parte) </strong>
                </p>
              </td>
            </tr>
            {/* <tr>
              <td>
                <p>Cookie analitico / Google Analytics</p>
              </td>
              <td>
                <p>
                  Questo cookie fa parte di Google Analytics e viene utilizzato
                  per l'analisi statistica del comportamento degli utenti sui
                  nostri siti web. Contiene un numero identificativo individuale
                  di Google Analytics.
                </p>
              </td>
              <td>
                <p>__ga</p>
              </td>
              <td>
                <p>Due anni</p>
              </td>
              <td>
                <p>Terza parte</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Cookie analitico /Google Analytics</p>
              </td>
              <td>
                <p>
                  Questo cookie fa parte di Google Analytics e viene utilizzato
                  per l'analisi statistica del comportamento degli utenti sui
                  nostri siti web. Con l'aiuto di questo cookie viene
                  controllata la frequenza di query dell'utilizzo del sito web.
                  In genere contiene un numero e viene memorizzato per 10
                  minuti.
                </p>
              </td>
              <td>
                <p>_gat</p>
              </td>
              <td>
                <p>10 minuti</p>
              </td>
              <td>
                <p>Terza parte</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Cookie analitico / Google Analytics</p>
              </td>
              <td>
                <p>
                  Questo cookie fa parte di Google Analytics e viene utilizzato
                  per l'analisi statistica del comportamento degli utenti sui
                  nostri siti web. Contiene un numero identificativo individuale
                  di Google Analytics e viene memorizzato per 24 ore
                </p>
              </td>
              <td>
                <p>__gid</p>
              </td>
              <td>
                <p>24 ore</p>
              </td>
              <td>
                <p>Terza parte</p>
              </td>
            </tr> */}
            <tr>
              <td>Cookie tecnico</td>
              <td>
                Mantiene le impostazioni sul consenso dei cookie date tramite il
                banner
              </td>
              <td>
                rcl_consent_given
                <br />
                rcl_marketing_consent
                <br />
                rcl_preferences_consent
                <br />
                rcl_statistics_consent
              </td>
              <td>
                <p>1 anno</p>
              </td>
              <td>
                <p>Prima parte</p>
              </td>
            </tr>
            <tr>
              <td>Matomo</td>
              <td>
                Memorizza le informazioni sulla provenienza degli utenti, ossa’
                indirizzo web attraverso cui gli utenti, selezionando un link,
                sono stati indirizzati alla pagina del proprio sito.{' '}
              </td>
              <td>_pk_ref</td>
              <td>6 mesi</td>
              <td>Terza parte</td>
            </tr>
            <tr>
              <td>Matomo</td>
              <td>
                Memorizza alcune informazioni sull’utente, tra cui l’ID utente,
                quale è associato ad ogni utente che atterra sul sito. Questa
                metrica consente di conoscere la popolarità del sito, ma non la
                frequenza.
              </td>
              <td>_pk_id</td>
              <td>13 mesi</td>
              <td>Terza parte</td>
            </tr>
            <tr>
              <td>Matomo</td>
              <td>
                Sono cookies di breve durata. Comunemente conosciuti come
                “cookies di sessione” Memorizzano temporaneamente i dati sulla
                navigazione dell’utente.
              </td>
              <td>
                _pk_ses
                <br />
                _pk_cvar
                <br />
                _pk_hsr
              </td>
              <td>30 minuti</td>
              <td>Terza parte</td>
            </tr>
            <tr>
              <td>Matomo</td>
              <td>
                Annotano se l’utente ha prestato o rimosso il proprio consenso.{' '}
              </td>
              <td>mtm_consent (o mtm_consent_removed)</td>
              <td>30 anni</td>
              <td>Terza parte</td>
            </tr>
            <tr>
              <td>Matomo</td>
              <td>
                Annotano se l’utente ha prestato il proprio consenso
                all’utilizzo dei cookies.{' '}
              </td>
              <td>mtm_cookie_consent</td>
              <td>30 anni</td>
              <td>Terza parte</td>
            </tr>
            <tr>
              <td>Matomo</td>
              <td>
                Questa tipologia di cookies non presenta informazioni personali
                sull’utente, è generico per tutti i visitatori.{' '}
              </td>
              <td>matomo_ignore</td>
              <td>30 anni</td>
              <td>Terza parte</td>
            </tr>
            <tr>
              <td>Matomo</td>
              <td>
                Viene usato per dare all’utente la possibilità di attivare e
                disattivare le proprie preferenze in merito alle Web Analytics.{' '}
              </td>
              <td>matomo_sessid</td>
              <td>14 giorni</td>
              <td>Terza parte</td>
            </tr>
            <tr>
              <td>YouTube</td>
              <td>
                Utilizzato per determinare se il visitatore ha accettato i
                cookie Marketing.
              </td>
              <td>CONSENT_[digits]</td>
              <td>2 anni</td>
              <td>Terza parte</td>
            </tr>{' '}
          </tbody>
        </table>
      </div>
      <br />

      <p>
        <strong>
          4. Obbligatorietà o facoltatività del consenso per l’operatività dei
          cookies
        </strong>
      </p>

      <p>
        In linea con la normativa applicabile in materia di trattamento dei dati
        personali, il preventivo consenso per l’uso dei cookie non è richiesto
        per i “cookie tecnici” che sono indispensabili per l’operatività di un
        sito (es. quelli usati per il solo scopo di trasportare una
        comunicazione attraverso una rete di comunicazione elettronica, o
        strettamente necessari per fornire un servizio espressamente richiesto
        dall’utente), mentre è necessario per quelli “statistici” o “analitici”
        di terza parte (se non sono adottati strumenti che riducono il potere
        identificativo dei cookie e la terza parte incrocia le informazioni
        raccolte con altre di cui già dispone) e per quelli di “profilazione”
        (es. quelli che forniscono analisi statistiche sull’utilizzo di un sito
        web e/o che creano profili di utenti per inviargli messaggi pubblicitari
        in linea con le preferenze che hanno espresso durante la navigazione).
      </p>

      <p>
        <strong>5. Come de-selezionare i cookie</strong>
      </p>
      <p>
        È possibile autorizzare, bloccare o eliminare (in tutto o in parte) i
        cookie attraverso le funzioni specifiche del tuo browser o attraverso
        componenti aggiuntive di terze parti.
      </p>
      <p>
        Attenzione, la disattivazione dei cookie tecnici del Sito potrebbe
        comportare che alcuni servizi o funzionalità del Sito non saranno
        disponibili o potrebbero non funzionare correttamente.
      </p>
      <p>
        Nel rispetto di quanto previsto dal citato provvedimento generale emesso
        dall’autorità Garante per la protezione dei dati personali l’8 maggio
        2014 “
        <a href="http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/3118884">
          Individuazione delle modalità semplificate per l'informativa e
          l'acquisizione del consenso per l'uso dei cookie
        </a>
        ”,{' '}
        <strong>
          l'utente può selezionare/de-selezionare di seguito quali cookie
          autorizzare
        </strong>{' '}
        accedendo al seguente link “<strong>Scelta dei cookie</strong>”
      </p>
      {/* <p>
        Per disattivare i cookie di Google Analytics è possibile scaricare e
        installare il componente aggiuntivo del browser per l’opt-out fornito da
        Google Analytics cliccando{' '}
        <a href="https://tools.google.com/dlpage/gaoptout">qui</a>. Questo
        componente aggiuntivo istruisce il codice javascript di Google Analytics
        (ga.js, analytics.js e dc.js) per impedire l’invio di informazioni a
        Google Analytics. Il componente aggiuntivo è disponibile per i browser
        più utilizzati.
      </p> */}
      <p>
        Ulteriori informazioni sulla privacy policy dei servizi Google (es.
        Youtube) sono reperibili agli indirizzi sopra riportati.
      </p>

      <p>
        Per informazioni su come impostare le preferenze sull’uso dei cookie
        tramite il browser è possibile consultare le seguenti istruzioni:
      </p>
      <p>
        <a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies">
          Internet Explorer
        </a>
        <br />
        <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">
          Firefox
        </a>
        <br />
        <a href="https://support.apple.com/kb/PH19214?viewlocale=it_IT&locale=en_US">
          Safari
        </a>
        <br />
        <a href="https://support.google.com/chrome/answer/95647?hl=it&p=cpn_cookies">
          Chrome
        </a>
      </p>
      <p>
        Se non è utilizzato dall’utente nessuno dei browser sopra elencati,
        selezionando “cookie” nella relativa sezione della guida del browser
        utilizzato è possibile scoprire dove si trova la cartella dei cookie e
        come disabilitarli e/o cancellarli.
      </p>
    </SimplePage>
  </Layout>
)

export default Privacy
